.emergency-parent {
    display: grid;
    grid-gap: 40px;
}

@media screen and (max-width: 800px) {
    .emergency-parent {
        display: inline-flex;
        max-width: 100%
    }
    .emergency-mobile-input {
        max-width: 100%;
        word-wrap: break-word;
    }
}

.emergency-card:first-child {
    grid-row: 1;
    grid-column: 1 / span 2;
}

.emergency-card:nth-child(2) {
    grid-row: 1 / span 2;
    grid-column: 3;
}

.emergency-card:last-child {
    grid-row: 2 / span 3;
    grid-column: 1 / span 2;
}

.emergency-list-item {
    display: flex;
}

.emergency-list-item p {
    width: 33%;
    font-weight: normal;
}

.emergency-contact-input {
    margin: 20px 0px;
    width: 100%;
}

.emergency-contact-input:first-child {
    margin-top: 0px;
}

.emergency-contact-input:last-child {
    margin-bottom: 0px;
}

.emergency-mobile-section-title {
    border-bottom: 1px solid var(--color-border);
    font-size: 18px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.emergency-mobile-section-line {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    justify-content: space-between;
}

.emergency-mobile-section-line p:first-child {
    margin-right: 10px;
}

.emergency-mobile-input {
    width: 300px;
}

.emergency-phone {
    margin: auto;
    border-radius: 30px;
    border: 8px solid black;
    width: 240px;
    height: 500px;
    background-color: #e4e4e4;
    position: relative;
}

.emergency-phone-navbar {
    border-radius: 22px 22px 0px 0px;
    background-color: #00B0EF;
    color: white;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.emergency-phone-header {
    border-bottom: 1px solid #b1b1b1;
    min-height: 40px;
    display: flex;
    padding-left: 10px;
    align-items: center;
    font-size: 11px;
    color: black;
}

.emergency-phone-contact {
    margin: 10px 0px;
    padding: 0px 10px;
    font-size: 14px;
    background-color: white;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
}

.emergency-phone-dots {
    display: flex;
    justify-content: center;
    font-size: 30px;
    color: black;
}

.emergency-phone-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 15px;
}

.emergency-phone-button {
    background-color: #00B0EF;
    color: white;
    border-radius: 5px;
    padding: 5px;
}

@media only screen and (max-width: 900px) {
    .emergency-parent {
        flex-direction: column;
    }

    .emergency-card {
        width: 100%;
    }
}
#emergency-root a{
    text-decoration: none;

}
#emergency-root a:hover{
    color: inherit;
    
}