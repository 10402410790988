
.eventcard-container {
    width: 100%;
    height: 105px;
    display: flex;
    position: relative;
    background-color: #f0f2f5;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
}

.eventcard-image {
    width: 50px;
    height: 50px;
    margin: auto 5px auto 10px;
    border-radius: 30px;
}

.eventcard-content {
    margin: 10px 60px 10px 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.eventcard-title {
    margin: auto auto 5px 0;
    font-weight: bold;
}

.eventcard-date {
    margin: 5px auto auto 0;
}

.eventcard-actions-container {
    height: 100%;
    width: 40px;
    position: absolute;
    top: 0;
    /* right: -50; */
    right: 0;
    border-radius: 10px;
    background: var(--bg-input);
}

.eventcard-action {
    height: 50%;
    width: 100%;
    display: flex;
}

.eventcard-action:nth-child(1) {
    border-bottom: 1px solid var(--border-eventcard-actions);
}