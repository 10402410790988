.__missions-card {
    border: 1px solid #00B0EF;
    background-color: #00B0EF0b;
    border-radius: 20px;
    padding: 10px 10px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    /* box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px; */
    /* box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px; */
}

.__missions-card-green {
    border: 1px solid #558b2f;
    background-color: #558b2f15;
}
.__missions-card-yellow {
    border: 1px solid #fbc02d;
    background-color: #fbc02d15;

}
.__missions-card-red {
    border: 1px solid #e53935;
    background-color: #e5393522;

}

.security-active-image-container {
    text-align: center;
    margin: auto;
    max-width: 400px;
    padding: 0;
    border: 1px black solid;
    transform: translateZ(0);
}

.security-sat-image-container {
    text-align: center;
    margin: auto;
    max-height: 400px;
    max-width: 400px;
    padding: 0;
    border: 1px black solid;
    transform: translateZ(0);
}

.security-active-image {
    height: 100%;
    width: 100%;
}

.security-sat-image {
    height: 100%;
    width: 100%;
}

.active-image-area {
    display: flex;
    justify-content: center;
    max-height: 600px;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
}

.active-image-container {
    text-align: center;
    margin: 0 auto;
    /* max-width: 600px; */
    padding: 0;
    /* border: 1px black solid; */
    transform: translateZ(0);
    border: 2px solid var(--primary-color);
}

.sat-image-area {
    display: flex;
    justify-content: center;
}

.sat-image-container {
    text-align: center;
    margin: auto;
    width: 1000px;
    height: 800px;
    padding: 0;
    border: 1px black solid;
    transform: translateZ(0);
}

.fade-in {
    animation: fadeIn 3s;
    -webkit-animation: fadeIn 3s;
    -moz-animation: fadeIn 3s;
    -o-animation: fadeIn 3s;
    -ms-animation: fadeIn 3s;
}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.language-selected {
    color: #00B0EF;
}

.geoloc-help-span {
    font-weight: bold;
}

.sidemenu-container .fontawesome-customicon {
    width: 25;
}

.dashboard-detail-card .fontawesome-customicon,
.dashboard-detail-card-small .fontawesome-customicon {
    width: 65;
}

.ant-tabs-left .ant-tabs-nav-list .fontawesome-customicon {
    width: 20;
}
